<template>
<div>
	<transition name='slideDown'>
		<div
			v-if='mode' 
			class='flash iconTextButton' 
			:class='[ flashClass ]'
			@click='close'
		>
			<svg class='smallIcon'>
				<use :xlink:href='flashIcon' />
			</svg>
			<span class='label' v-html='$store.state.flash.message'></span>
		</div>
	</transition>
</div>
</template>

<script>
export default {
	name: 'Flash',
	data() { return { timer: null }},
	computed: {
		mode() { return this.$store.state.flash.mode },
		apiReturned() { return this.$store.state.main.apiReturned },
		flashClass() {
			if (this.mode === 'error') return 'alert'
			else if (this.mode === 'alert') return 'accent'
			else if (this.mode === 'action') return 'success'
			else if (this.mode === 'login') return 'success'
			else return null
		},
		flashIcon() {
			if (this.mode === 'error' || this.mode === 'alert') return '#alert'
			else if (this.mode === 'action' || this.mode === 'login') return '#check'
			else return null
		}
	},
	methods: {
		close() { this.$store.dispatch('flash/reset') },
		getError(err) {
			if (['ERRINPUT', 'ERRCANCEL', 'ERRAUTHENTICATE'].includes(err.code)) return false
			else if (err.code === 'ERRCONNECTION') return 'connectionErr'
			else return 'generalErr'
		},
		startTimer() {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => this.$store.dispatch('flash/reset'), 3000)
		}
	},
	watch: {
		apiReturned() {
			const err = this.$store.state.main.apiError
			if (err && err.status !== 400) {
				const code = this.getError(err)
				if (code) this.$store.dispatch('flash/showError', code)
			} 
		},
		mode(v) {
			if (v) this.startTimer()
		}
	},
	mounted() {
		if (this.mode && !this.timer) this.startTimer()
//		this.$store.dispatch('flash/reset')
	}
}
</script>

<style lang='scss'>
.flash {
	@include absTopLeft;
	width: 100%;
	height: $size-control-height + $size-gutter * 2 + 1;
	z-index: 50000;

	.label { 
		dislay: flex; align-items: center; 
		svg { margin: 0 $size-gutter; }
	}
}
</style>
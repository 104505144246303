<template>
<div class='profile'>
	<div class='appNavButton profileButton' @click='contextMenu=true'>
		<div class='initials'>
			<div>{{$store.state.profile.user.firstname.charAt(0)}}{{$store.state.profile.user.lastname.charAt(0)}}</div>
		</div>
		<span class='label'>{{$store.state.profile.user.firstname}} {{$store.state.profile.user.lastname}}</span>
	</div>	
	<ContextMenu
		v-if='contextMenu'
		:pos='{ el: $el, align: "center" }'
		@close='contextMenu=false'
	>
		<div class='menu' ref='menu' :class='{ manager: $store.state.profile.user.manager && !$route.path.includes("manager"), showUpgrade }'>

			<div class='profileDetails'>
				<div class='initials'>
					<div>{{$store.state.profile.user.firstname.charAt(0)}}{{$store.state.profile.user.lastname.charAt(0)}}</div>
				</div>
				<div class='contact'><div>
					<div>{{$store.state.profile.user.firstname}} {{$store.state.profile.user.lastname}} <template v-if='!$store.state.profile.user.clinicianType==="demo"'>({{$store.state.profile.user.username}})</template></div>
					<div>{{$store.state.profile.user.email}}</div>
					<div v-if='$store.state.profile.organization'>{{$store.state.profile.organization.title}}</div>
				</div></div>
			</div>

			<router-link v-if='$store.state.profile.user.manager && !$route.path.includes("manager")' tag='div' :to='{ name: "teams" }'  class='iconTextButton'>
					<svg class='smallIcon'>
						<use xlink:href='#edit' />
					</svg>
					<span class='label'>Manager Portal</span>					
			</router-link>	

			<div v-if="showUpgrade" class="upgradePlan" @click="upgrade"><div>
				<div class="upgrade">
					<div>Need more Accounts?</div>
					<div>Upgrade Your Plan</div>
				</div>
				<div class="upgradeFeatures">
					<div>
						<div class='bullet'><svg><use xlink:href='#check' /></svg></div>
						<div>Easily add your colleagues!</div>
					</div>
					<div>
						<div class='bullet'><svg><use xlink:href='#check' /></svg></div>
						<div>SimpleSet ensures everyone stays in sync when sharing clients, decreasing confusion and increasing quality of care.</div>
					</div>
				</div>
			</div></div>

			<Swap v-if='teamSwap && !$route.path.includes("manager")' />

			<div class='controls'>

				<div class='iconTextButton' @click='toggleBeacon'>
					<svg class='smallIcon'>
						<use xlink:href='#help' />
					</svg>
					<span class='label'>{{$t('elements.nav.help')}}</span>
				</div>

				<router-link tag='div' :to='{ name: $route.path.includes("manager") ? "managerSettings" : "clinicianSettings" }'  class='iconTextButton'>
					<svg class='smallIcon'>
						<use xlink:href='#settings' />
					</svg>
					<span class='label'>Settings</span>					
				</router-link>	

				<div class='appNavButton' @click='$store.dispatch("auth/logout")'>
					<svg class='smallIcon'>
						<use xlink:href='#logout' />
					</svg>
					<span class='label'>{{$t('elements.nav.logout')}}</span>
				</div>										

			</div>

			<div class="shop"  @click='gotoShop'>
				<img src="/img/keepItSimple.webp" />
				<div>
					<div>We've got Merch!</div>
					<div>Visit simpleset.shop</div>
				</div>
			</div>

		</div>
	</ContextMenu>
</div>
</template>


<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import ContextMenu from '@/components/common/ContextMenu'	
import Swap from './Swap'
import Upgrade from './Upgrade'

export default {
	name: 'Profile',
	mixins: [smallModalMixin],
	components: { ContextMenu, Swap },
	data() { return {
		working: false,
		contextMenu: false,
		timer: null
	}},
	computed: {
		teamSwap() {
			const { teams } = this.$store.state.profile
			const teamIds = teams ? Object.values(teams).filter(t=>t.teamSwap).map(t=>t.id) : []
			return teamIds.length ? teamIds : null
		},
		showUpgrade() {
			return this.$store.state.profile.user.clinicianType==="practitioner" && !this.$store.state.profile.user.orgId
		}	
	},
	methods: {
		toggleBeacon() {
			/*eslint-disable */
			if (this.$route.path.includes('clinician')) Beacon('init', 'e041befd-3cec-41ea-a041-7f0fba810899')
			else if (this.$route.path.includes('manager')) Beacon('init', '2bece4fa-72c8-4d78-a602-45d3b7320f50')
			Beacon('on', 'close', () => setTimeout(()=>Beacon('destroy'), 1000))
			Beacon('open')
			/*eslint-enable */
		},
		upgrade() {
			this.showSmallModal(Upgrade)			
		},
		gotoShop() {
			window.open("https://simpleset.shop")
		}
	},
	watch: {
		contextMenu(v) {
			if (v) {
				this.$nextTick(()=>{
					if (this.$refs.menu) this.$refs.menu.style.height=`${window.innerHeight-150}px`
				})
			}
		}
	}
}

</script>


<style lang='scss'>
.profile {
	&:after { display: none; }
	.contextMenuWrapper > div { padding: 0; }

	.profileButton {
		.initials {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: $size-font-standard;
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
			background: $color-primary-accent;
			margin-right: $size-gutter;
		}
	}

	.menu { 
		padding: $size-gutter * 2;
		width: 360px; 
		background: $color-neutral-panel;
		display: grid;
		grid-template-rows: auto 1fr;
		&.manager { grid-template-rows: auto auto 1fr; }
		&.showUpgrade { grid-template-rows: auto 1fr auto; }
		grid-gap: $size-gutter * 2;
		> div {
			border-radius: 2px;
			overflow: hidden;
			background: #fff;	
			border: 1px solid $color-neutral-shadow;
		}
	}

	.upgradePlan {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		> div { width: 75%; margin: auto; display: flex; flex-wrap: wrap; justify-content: center; }

		.upgrade {
			color: #fff;
			text-align: center;
			line-height: $size-lineHeight-large;
			background: $button-gradient-success;
			padding: $size-gutter * 3;
			border-radius: 3px;
			width: 100%;
			> div:first-child { font-size: $size-font-large; }
			> div:last-child { font-size: $size-font-standard; text-decoration: underline;}
		}
		.upgradeFeatures {
			margin-left: auto;
			margin-right: auto;
			margin-top: $size-gutter * 3;
			> div { 
				display: flex;
				align-items: center;

				margin: 14px 0;
				font-size: $size-font-standard;
				line-height: $size-lineHeight-standard;
				&:first-child { margin-top: 0; }
				&:last-child { margin-bottom: 0; }
				> div { display: flex; align-items: center; }
			}
			.bullet {
				margin-right: $size-gutter * 2;
				display: flex;
				align-items: center;
				justify-content: center;
				svg { width: 20px; height: 20px; color: $color-success; }
			}
		}
	}

	.profileDetails {
		background: #fff;
		border-radius: 7px;
		border: 1px solid $color-neutral;
		display: flex;
		align-items: center;

		.initials {
			width: 56px;
			height: 56px;
			flex: 0;
			min-width: 56px;
			margin: $size-gutter * 2;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 24px;
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
			background: $color-primary-accent;
			margin-right: $size-gutter;
		}		

		.contact {
			flex: 1;
			display: flex;
			align-items: center;
			> div {
				padding: 0 $size-gutter * 2;
				line-height: $size-lineHeight-standard;
				> div { font-size: $size-font-standard; color: $color-black; }
				> div:first-child { font-size: $size-font-large; color: $color-primary-accent; margin-bottom: $size-gutter; }
			}
		}
	}

	.shop {
		display: flex;
		align-items: center;
		cursor: pointer;
		img { flex: 1; min-width: 0;}
		> div { flex: 1; text-align: center; }
		> div > div:first-child { color: $color-primary-accent; font-size: $size-font-large; margin-bottom: $size-gutter * 2;}
		> div > div:last-child { font-size: $size-font-standard; }
	}

}
</style>


import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetchTeam: '/'
	},
	post: {
		swap: '/${id}'
	}
}

export const teamApi = buildApi('team', routes, '/clinician/swap')


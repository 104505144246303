<template>
<div class='appNav'>
	<div class='leftPanel'>	
		<slot name='leftNav'></slot>
		<router-link v-if="$store.state.profile.user.emailErr" tag='div' :to='{ name: $route.path.includes("manager") ? "managerSettings" : "clinicianSettings" }' class="emailErr">
			ALERT:  Emails to your account are bouncing.		
		</router-link>
	</div>
	<div class='rightPanel'>
		<slot name='rightNav'></slot>
		<!--
		<div class='appNavButton' @click='showFeedback'>
			<svg class='smallIcon'>
				<use xlink:href='#feedback' />
			</svg>
			<span class='label'>{{$t('elements.nav.feedback')}}</span>
		</div>
	-->
		<Profile />
	
	</div>
</div>
</template>

<script>
import { smallModalMixin } from './mixins/modal'
import ConfirmModal from '@/components/common/modals/Confirm'
import Profile from '@/components/common/profile/Profile'

export default {
	name: 'AppNav',
	components: { Profile },
	mixins: [smallModalMixin],
	methods: {
		logout(e) {
			if (e) {
				this.showSmallModal(ConfirmModal, {
					title: this.$t('views.confirmLogout.title'),
					message: this.$t('views.confirmLogout.p1'),
					ok: this.$t('elements.nav.logout'),
					fn: () => this.$store.dispatch('auth/logout')
				})
			} else {
				this.$store.dispatch('auth/logout')
			}
		},
	},
}
</script>

<style lang='scss'>
.appNav {
	display: flex;
	position: relative;
	background:  #fff;
	height: $size-control-height + 14px;
	padding: 0 $size-gutter + 1;

	> .leftPanel {
		display: flex;
		flex: 0;
		margin-top: auto;
		margin-bottom: auto;
		> .logoButton {
			display: flex;
			align-items: center;
			margin-right: $size-gutter * 2;
			margin-left: $size-gutter * 2;
			cursor: pointer;
		}
	}

	.emailErr {
		white-space: nowrap;
		display: flex;
		align-items: center;
		padding-left: $size-gutter * 4;
		font-size: $size-font-standard;
		color: $color-alert;
		text-decoration: underline;
		cursor: pointer;
	}

	> .rightPanel {
		flex: 0;
		margin-top: auto;
		margin-bottom: auto;		
		margin-left: auto;
		display: flex;
	}

	a { text-decoration: none; }
}
</style>
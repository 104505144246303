<template>
<SmallModal class='updradeToClinic' @close='$emit("close")'>
	<template v-slot:title>
		Upgrade Your Plan to Add Your Colleagues
	</template>
	<template v-slot:message>

		<div class="upgradeFeatures">
			<div>
				<div class='bullet'><svg><use xlink:href='#check' /></svg></div>
				<div>Share content - one person creates, everyone can use</div>
			</div>
			<div>
				<div class='bullet'><svg><use xlink:href='#check' /></svg></div>
				<div>Co-treat and triage - load programs assigned by others, instantly pick up where they left off, never re-write a program</div>
			</div>
			<div>
				<div class='bullet'><svg><use xlink:href='#check' /></svg></div>
				<div>Clients see programs in one place online - no matter who assigns them</div>
			</div>
		</div>
		<div class='howmany'>
			How many SimpleSet accounts do you need?
		</div>
		<div class='seatCounter'>
			<div class='less' @click='subSeat'>
				<svg class='smallIcon'>
					<use xlink:href='#minus' />
				</svg>
			</div>
			<div class='seats'>{{seats}}</div>
			<div class='more' @click='addSeat'>
				<svg class='smallIcon'>
					<use xlink:href='#plus' />
				</svg>
			</div>
		</div>
		<RichText 
			v-model='comments' 
			placeholder='Any additional comments?'
		/>
		<div class='questions'>Questions about this?  Contact <a href="mailto:sales@simpleset.net">sales@simpleset.net</a></div>
		<transition name='fade'><Loading v-show='loading' /></transition>
	</template>
	<template v-slot:buttons>
		<div v-if='selfServe' class='iconTextButton success' @click='checkout'>
			<svg class='smallIcon'>
				<use xlink:href='#angleRight' />
			</svg>
			<span class='label'>Next</span>
		</div>
		<div v-else class='iconTextButton primary' @click='email'>
			<svg class='smallIcon'>
				<use xlink:href='#email' />
			</svg>
			<span class='label'>Request</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { generalApi } from '@/services/api/modules/clinician/general'
import { smallModalMixin } from '@/components/common/mixins/modal'
import SmallModal from '@/components/common/modals/SmallModal'
import AlertModal from '@/components/common/modals/Alert'
import Loading from '@/components/common/Loading'
import RichText from '@/components/common/RichText'
import TextInput from '@/components/common/TextInput'

export default {
	name: 'UpgradeToClinic',
	mixins: [smallModalMixin],
	components: {
		SmallModal,
		RichText,
		TextInput,
		Loading
	},
	data() {
		return {
			comments: '', 
			seats: 2,
			loading: false
		}
	},
	computed: {},
	methods: {
		async email() {
			if (this.loading) return
			this.loading = true
			await generalApi.touch('upgrade', { seats: this.seats, comments: this.comments })
			this.$emit('close')
			this.$store.dispatch('flash/showAction', 'thanks')
			this.showSmallModal(AlertModal, {
				title: 'Thanks for the info!',
				message: 'We\'ll be in touch with you shortly...'
			})
		},
		addSeat() {
			this.seats++
		},
		subSeat() {
			if (this.seats > 2) this.seats--
		}
	}
}
</script>

<style lang='scss'>
.updradeToClinic {
	.loading {
		background: rgba($color-white, .3);
	}
	header { text-align: center; }


	.howmany {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-large;
		margin: $size-gutter * 3;		
	}

	.seatCounter {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: $size-gutter * 4;
		.less, .more {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: $button-gradient-primary;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.seats {
			font-size: $size-font-large;
			font-weight: bold;
			color: $color-primary-accent;
			margin: 0 $size-gutter * 2; 
		}
	}

	.richText {
		border: 1px solid $color-neutral-shadow;
		margin-top: $size-gutter * 3;
	}

	.questions {
		font-size: $size-font-small;
		padding-top: $size-gutter * 3;
		margin-bottom: $size-gutter;
		text-align: center;
		a { color: $color-primary-accent; }
	}

	.upgradeFeatures {
		margin-left: auto;
		margin-right: auto;
		width: 80%;
		margin-bottom: $size-gutter * 5;
		> div { 
			display: flex;
			align-items: center;

			margin: 14px 0;
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			&:first-child { margin-top: 0; }
			&:last-child { margin-bottom: 0; }
			> div { display: flex; align-items: center; }
		}
		.bullet {
				margin-right: $size-gutter * 2;
				display: flex;
				align-items: center;
				justify-content: center;
				svg { width: 20px; height: 20px; color: $color-primary-accent; }
		}
	}

}
</style>
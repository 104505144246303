<template>
<SmallModal @close='$emit("close")' class='swapLogin' :class='{ shake }'>
	<template v-slot:title>Swap user: {{user.lastname}}, {{user.firstname}}</template>
	<template v-slot:message>
		<PasswordInput 
			ref='passwordInput' 
			v-model='password'
			:placeholder='$t("views.swap.phPassword")' 
			:stopFill='true'
			@submit='login'
		/>
		<transition name='fade'><Loading v-show='working' /></transition>
	</template>
	<template v-slot:buttons>
		<div class='submitButton iconTextButton primary' @click='login'>
			<svg class='smallIcon'>
				<use xlink:href='#swap' />
			</svg>
			<span class='label'>{{$t("views.swap.btnSubmit")}}</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { teamApi } from '@/services/api/modules/clinician/team'
import SmallModal from '@/components/common/modals/SmallModal'
import PasswordInput from '@/components/common/PasswordInput'
import Loading from '@/components/common/Loading'

export default {
	name: 'SwapLogin',
	components: { PasswordInput, SmallModal, Loading },
	props: ['user'],
	data: () => ({ password: null, shake: false, working: false }),
	computed: {
	//	staff() { return this.$store.state.team.team[this.$store.state.team.selected] },
	//	selected() { return this.$store.state.team.selected }
	},
	methods: {
		failed() {
			this.shake = true
			this.$refs.passwordInput.reset()
			setTimeout(() => { this.shake = false }, 1000)
		},
		setRestoreUrl() {
			const tab = this.$store.state.exerciseBrowser.tab
			const setIds = this.$store.getters['setBuilder/router'] || ''
			localStorage.setItem(`swap-${this.$store.state.profile.user.username}`, `${window.location.origin}/#/clinician/designer/exerciseBrowser/${tab}/setBuilder/${setIds}`)
		},
		async login() {
			if (this.working) return
			this.working = true
			this.setRestoreUrl()
			const id = this.user.id
			const username = this.user.username
			const [err] = await teamApi.touch('swap', { id, password: this.password })
			this.working = false
			if (!err) {
				const url = localStorage.getItem(`swap-${username}`) || window.location.origin
				window.open(url)
				window.close()
			} else {

				this.failed()
			}
		}
	},
	watch: {
		user() { this.$refs.passwordInput.focus() }
	},
	mounted() { this.$refs.passwordInput.focus() }
}
</script>

<style lang='scss'>
.swapLogin {


	.passwordInput { border: 1px solid $color-neutral-shadow; }

	.submitButton svg { width: 20px; }
}
</style>
<template>
<div class='swap'>

	<SearchInput 
		v-model='search'
		@submit='fetchTop'
		placeholder='Swap active user...'
	/>

	<div class='content'>
		<Scroll :fetchNext='fetchNext'>
			<div class='list'>
				<div v-for='user in items' :key='user.id' @click='showSwap(user)' :class='{ selected: selected===user.id }'>
					<div class='initials'>
						<div>{{user.firstname.charAt(0)}}{{user.lastname.charAt(0)}}</div>
					</div>
					<div class='nameCol'>
						<div>{{user.lastname}}, {{user.firstname}}</div>
						<div>{{user.email}}</div>
					</div>
				</div>
			</div>
		</Scroll>
	</div>

	<transition name='fade'><Loading v-show='working' /></transition>

</div>
</template>


<script>
import { debounce } from 'lodash'
import { teamApi } from '@/services/api/modules/clinician/team'
import { smallModalMixin } from '@/components/common/mixins/modal'
import Scroll from '@/components/common/Scroll'	
import ContextMenu from '@/components/common/ContextMenu'	
import Loading from '@/components/common/Loading'
import SearchInput from '@/components/common/SearchInput'
import Login from './Login'

export default {
	name: 'Swap',
	mixins: [smallModalMixin],
	components: { ContextMenu, Scroll, Loading, SearchInput, Login },
	data() { return {
		working: false,
		contextMenu: false,
		timer: null,
		items: [],
		total: null,
		selected: null,
		search: '',
		debouncedFetchTop: debounce(function() {
			this.fetchTop()
		}.bind(this), 1000, { leading: false, trailing: true })
	}},
	computed: {
		swapAs() { 
			return this.items.find(i=>i.id===this.selected)
		}
	},
	methods: {
		async fetchTop() {
			this.debouncedFetchTop.cancel()
			this.working = true
			const payload = { search: this.search }
			const [err, result] = await teamApi.touch('fetchTeam', payload)
			this.working = false
			if (!err) {
				this.total = result.total
				this.items = result.items
			}
		},
		async fetchNext() {
			this.working = true
			const payload = { search: this.search, skip: this.items.length }
			const [err, result] = await teamApi.touch('fetchTeam', payload)
			this.working = false
			if (!err) {
				this.items.push(...result.items)
			}
		},
		showSwap(user) {
			this.selected = user.id 
			this.showSmallModal(Login, { user })
		}
	},
	watch: {
		search() {
			this.debouncedFetchTop()
		}
	},
	mounted() { this.fetchTop() }
}

</script>


<style lang='scss'>
.swap {
	position: relative;
	display: grid;
	grid-template-rows: auto 1fr;

	.content { position: relative; }
/*
	.searchInput {
		svg { left: 18px; }
		input { padding-left: 52px; }
	}
*/
	.list {

		> div {
			height: $size-control-height;
			display: flex;
			align-items: center;
			padding: 0 $size-gutter * 2;
			line-height: $size-lineHeight-small;
			cursor: pointer;
			.nameCol {
				> div:first-child { font-size: $size-font-standard; }
				> div:last-child { font-size: $size-font-small; color: $color-primary-accent; }
			}
			&:hover { background: $color-neutral-silver; }
			&.selected { background: $color-focus; }
		}

		.initials {
			width: 16px;
			height: 16px;
			min-width: 16px;
			border-radius: 50%;
			font-size: $size-font-small;
			font-weight: bold;
			color: $color-primary-accent;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: $size-gutter * 2;
		}

	}
}
</style>

